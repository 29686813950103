import { combineReducers } from 'redux';
import { user } from './user/reducer';
import { business } from './business/reducer';
import { autocomplete } from './autocomplete/reducer';

const rootReducer = combineReducers({
  user,
  business,
  autocomplete,
});

export default rootReducer;
