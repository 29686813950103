import { CARGAR_AUTOCOMPLETE_EXTIO, CARGANDO_AUTOCOMPLETE } from "./actions";

export const autocomplete = (state = {}, action) => {
  switch (action.type) {
    case CARGAR_AUTOCOMPLETE_EXTIO:
      return action.autocomplete;
    case CARGANDO_AUTOCOMPLETE:
      return Object.assign({}, state, { cargando_autocomplete: action.estado });
    default:
      return state;
  }
};
