import AutocompleteService from "../../services/api/autocompletes";

// Tipos de acciones
export const CARGAR_AUTOCOMPLETE_EXTIO = "CARGAR_AUTOCOMPLETE_EXTIO";
export const CARGANDO_AUTOCOMPLETE = "CARGANDO_AUTOCOMPLETE";

// Creadores de acción
export const loadAutocompleteExito = (autocomplete) => {
  return { type: CARGAR_AUTOCOMPLETE_EXTIO, autocomplete };
};

export const chargingAutocomplete = (estado) => {
  return { type: CARGANDO_AUTOCOMPLETE, estado };
};

export const loadAutocomplete = (path = "") => {
  return function (dispatch) {
    dispatch(chargingAutocomplete(true));
    return AutocompleteService.getAutocomplets(path)
      .then(
        (autocomplete) => {
          if (autocomplete) {
            dispatch(chargingAutocomplete(false));
            dispatch(loadAutocompleteExito(autocomplete));
          }
        },
        (error) => {
          if (error) {
            dispatch(chargingAutocomplete(false));
            throw error;
          }
        }
      )
      .catch((error) => {
        throw error;
      });
  };
};
