// Tipos de acciones
export const CARGAR_USUARIO_EXITO = "CARGAR_USUARIO_EXITO";
export const CARGAR_PERMISOS_EXITO = "CARGAR_PERMISOS_EXITO";

// Creadores de acción
export const cargarUsuarioExito = (usuario) => {
  return { type: CARGAR_USUARIO_EXITO, usuario };
};

export const cargarPermisosExito = (permisos) => {
  return { type: CARGAR_PERMISOS_EXITO, permisos };
};
