import { createStore, applyMiddleware } from "redux";
import rootReducer from "./data/reducer";
import throttle from "lodash/throttle";
import LocalStorage, { STATE_APP_KEY } from "./LocalStorage";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import thunk from "redux-thunk";
import Cookie from "js-cookie";

const LOGGER = "http://app.sisreal.com:logger";

const logger = (store) => (next) => (action) => {
  if (typeof action === "object") {
    const serializedState = JSON.stringify(action);
    localStorage.setItem(LOGGER, serializedState);
    next(action);
  }
};

const store = createStore(
  rootReducer,
  LocalStorage.loadState(),
  applyMiddleware(thunk, logger, reduxImmutableStateInvariant())
);

store.subscribe(
  throttle(() => {
    if (
      (Cookie.get("access_token") && Cookie.get("access_token") !== "") ||
      (localStorage.getItem("access_token") &&
        localStorage.getItem("access_token") !== "")
    ) {
      LocalStorage.saveState(store.getState());
    }
  }, 1000)
);

export function createStorageListener(store) {
  return (event) => {
    if (event.key === STATE_APP_KEY) {
      let logger;
      try {
        logger = JSON.parse(localStorage.getItem(LOGGER));
      } catch (e) {
        logger = undefined;
      }

      if (logger && logger.type && logger.type.indexOf("_EXITO") > 0) {
        store.dispatch(logger);
        localStorage.setItem(LOGGER, "");
      }
    }
  };
}

window.addEventListener("storage", createStorageListener(store));

export default store;
