import apiConfig from './config';
import helper from '../../utils/helper';

const getAutocomplets = (queryString, success, error) => {
  const business_id =
    localStorage.getItem(apiConfig.constants.BUSINESS_ID) || null;
  const token = helper.getToken();
  let path = '';
  if (queryString === '' || queryString === undefined) {
    path = `?business_id=${business_id}`;
  } else {
    path = `${queryString}&business_id=${business_id}`;
  }
  return fetch(
    `${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/autocompletes${path}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  )
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const getAutocomplet = (id, success, error) => {
  const business_id =
    localStorage.getItem(apiConfig.constants.BUSINESS_ID) || null;
  const token = helper.getToken();
  return fetch(
    `${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/autocompletes?business_id=${business_id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  )
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const createAutocomplet = (data, success, error) => {
  const business_id =
    localStorage.getItem(apiConfig.constants.BUSINESS_ID) || null;
  const token = helper.getToken();
  return fetch(
    `${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/autocompletes?business_id=${business_id}`,
    {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  )
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const updateAutocomplet = (data, success, error) => {
  const business_id =
    localStorage.getItem(apiConfig.constants.BUSINESS_ID) || null;
  const token = helper.getToken();
  return fetch(
    `${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/autocompletes/${data.id}?business_id=${business_id}`,
    {
      method: 'put',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  )
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const deleteAutocomplet = (id, success, error) => {
  const business_id =
    localStorage.getItem(apiConfig.constants.BUSINESS_ID) || null;
  const token = helper.getToken();
  return fetch(
    `${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/autocompletes/${id}?business_id=${business_id}`,
    {
      method: 'delete',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  )
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const exportedObject = {
  getAutocomplets,
  getAutocomplet,
  createAutocomplet,
  updateAutocomplet,
  deleteAutocomplet,
};

export default exportedObject;
