import { CARGAR_USUARIO_EXITO, CARGAR_PERMISOS_EXITO } from "./actions";

export const user = (state = {}, action) => {
  switch (action.type) {
    case CARGAR_USUARIO_EXITO:
      return {
        ...state,
        ...action.usuario,
      };
    case CARGAR_PERMISOS_EXITO:
      return {
        ...state,
        permisos: action.permisos,
      };
    default:
      return state;
  }
};
