import React from 'react';
import apiConfig from '../services/api/config';
import Cookie from 'js-cookie';

const validation = {
  isFormValid: function (validators, state) {
    let status = true;
    if (validators) {
      Object.keys(validators).forEach((fieldName) => {
        this.uploadValidators(fieldName, state[fieldName], validators);
      });

      Object.keys(validators).forEach((field) => {
        if (!validators[field].valid) {
          status = false;
        }
      });

      return {
        status,
        validators,
      };
    }

    return {
      status,
    };
  },
  uploadValidators: function (fieldName, value, state_validators) {
    if (!state_validators) {
      return [];
    }

    value = value || '';
    const validators = Object.assign({}, state_validators);
    validators[fieldName].errors = [];
    validators[fieldName].state = value;
    validators[fieldName].valid = true;
    validators[fieldName].rules.forEach((rule) => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value)) {
          validators[fieldName].errors.push(rule.message);
          validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === 'function') {
        if (!rule.test(value)) {
          validators[fieldName].errors.push(rule.message);
          validators[fieldName].valid = false;
        }
      }
    });

    return validators;
  },
  actualizarListaErrores: function (errors, validators) {
    if (errors) {
      Object.keys(errors).forEach((field) => {
        if (validators[field]) {
          validators[field].errors = [];
          validators[field].valid = false;
          errors[field].forEach((message) => {
            validators[field].errors.push(message);
          });
        }
      });
      return validators;
    }
    return validators;
  },
  mostrarErroresValidacion: function (fieldName, validators) {
    if (!validators) {
      return '';
    }
    const result = '';
    const validator = validators[fieldName];
    if (validator && !validator.valid && validator.errors.length > 0) {
      const errors = validator.errors.map((info, index) => (
        <li style={{ display: 'flex', alignItems: 'center' }} key={index}>
          <i style={{ marginRight: 4 }} className="bx bxs-error-circle"></i>
          {info}
        </li>
      ));
      return <ul className="errors">{errors}</ul>;
    }
    return result;
  },
};

const getToken = () => {
  return (
    Cookie.get(apiConfig.constants.ACCESS_TOKEN_KEY) ||
    localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) ||
    null
  );
};

const exportedObject = {
  validation,
  getToken,
};

export default exportedObject;
