import React, { Suspense } from "react";
import { Provider } from "react-redux";
import "@fortawesome/fontawesome-pro/css/all.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import apiConfig from './services/api/config';
import "./App.less";

// import 'rsuite/lib/styles/index.less';

// Store
import store from "./store";
// import Cookie from 'js-cookie';

const Private = React.lazy(() => import("./scenes/Private/Private"));
const Public = React.lazy(() => import("./scenes/Public/Public"));

function App() {
  return (
    <Provider store={store}>
      <Suspense
        fallback={
          <div className="loading">
            <div className="loading__chase">
              <div className="loading__chase--dot"></div>
              <div className="loading__chase--dot"></div>
              <div className="loading__chase--dot"></div>
              <div className="loading__chase--dot"></div>
              <div className="loading__chase--dot"></div>
              <div className="loading__chase--dot"></div>
            </div>
          </div>
        }
      >
        <Router>
          <Switch>
            <Route path="/public" name="Página Inicio" component={Public} />
            <Route path="/" name="Home" component={Private} />
          </Switch>
        </Router>
      </Suspense>
    </Provider>
  );
}

export default App;
